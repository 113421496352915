import React from 'react'

export default () => <svg className='icon icon-classes' width='400' height='400' viewBox='0 0 400 400' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M200 400C310.457 400 400 310.457 400 200C400 89.5431 310.457 0 200 0C89.5431 0 0 89.5431 0 200C0 310.457 89.5431 400 200 400Z' fill='#E078B6' />
  <g style={{ 'mixBlendMode': 'overlay' }}>
    <path style={{ 'mixBlendMode': 'overlay' }} fillRule='evenodd' clipRule='evenodd' d='M90.9794 358.644L0 306L36 0L55.8546 1.9137C131.339 30.5169 185 103.492 185 189C185 260.532 147.447 323.293 90.9794 358.644Z' transform='translate(215 11)' fill='url(#paint0_radial)' />
  </g>
  <path fillRule='evenodd' clipRule='evenodd' d='M19.5455 0.70224C18.5521 50.3746 10.1875 105.703 4.72741 141.82C1.8026 161.167 -0.288767 175.001 0.0325828 179.5C1.03258 193.5 12.6664 233.684 23.0326 258.5L78.5326 133C76.4177 131.094 74.9745 129.623 73.2762 127.487C71.7882 125.615 71.2431 123.17 71.6649 120.816C76.3146 94.8658 84.6524 71.3291 92.667 48.7047C96.8645 36.8557 100.973 25.257 104.417 13.6929C83.2059 4.87064 59.9381 0 35.5326 0C30.1448 0 24.8125 0.237396 19.5455 0.70224Z' transform='translate(167.967)' fill='#F5C6B2' />
  <path d='M0 35C22.2045 21.4596 39.6792 12.6201 56.5 0C59.1927 9.10411 58.3207 14.444 60 25.5C42.9999 38.9457 28.9173 49.2573 11 59L0 35Z' transform='translate(229 142.5) scale(-1 1)' fill='#F3F3F3' />
  <path d='M39.5375 0.0261047C39.9755 -0.076885 40.4374 0.131946 40.6515 0.527649C74.4674 63.0339 98.3109 105.106 95.2859 122.352C87.3213 148.096 82.3861 162.237 74.7737 179.297C72.9189 183.454 69.2334 186.652 64.7156 187.206C58.139 188.014 52.7194 187.123 46.1066 183.875C41.1165 181.424 38.6157 175.864 39.0186 170.32C44.0668 100.844 31.8579 72.2681 3.51532 37.5459C-0.920405 32.1117 -1.38195 24.2121 3.41713 19.0959C13.1328 8.73847 23.0539 3.90242 39.5375 0.0261047Z' transform='translate(284.786 131.148) scale(-1 1)' fill='#F3F3F3' />
  <path d='M0 0C25.6704 7.80892 42.8812 10.4081 68.3 22C70.3894 31.391 70.2909 36.5719 71.8 45.5C40.6371 37.5679 25.2495 34.1056 4.19999 28C5.78757 24.8415 2.38952 12.909 0 0Z' transform='translate(244.3 97.5) scale(-1 1)' fill='#F3F3F3' />
  <path d='M0 13.5C22.5415 5.53441 41.3668 2.19718 64.1 0C65.8657 9.84425 65.6182 15.8847 67.4 25C44.5479 24.6923 3.9 28 3.9 28C3.78903 28.362 1.85265 19.9814 0 13.5Z' transform='translate(244.9 84) scale(-1 1)' fill='#F3F3F3' />
  <path d='M0 0C28.098 33.9472 44.7225 43.1597 43.9512 112.213C43.9437 112.888 43.8888 113.586 43.7444 114.245C42.1159 121.672 36.7323 127.912 33.8341 123.676C33.5462 123.255 33.4499 122.719 33.4697 122.21C35.6417 66.2831 27.7204 45.4724 0 0Z' transform='translate(279 171.5) scale(-1 1)' fill='#219653' />
  <g style={{ 'mixBlendMode': 'lighten' }}>
    <g style={{ 'mixBlendMode': 'lighten' }} transform='translate(167.5 0.5)'>
      <path d='M34.5 0C25.7618 70.7722 23.3902 100.12 15.5 176.5C15.0019 206.583 33.139 253.478 58.5 312C53.1086 320.868 48.0309 317.098 44 312C38.0911 298.806 15.6698 249.839 0 179C7.89777 122.434 14.7688 76.2927 20.5 0H34.5Z' fill='#E3E2E2' fillOpacity='0.45' />
    </g>
  </g>
  <g style={{ 'mixBlendMode': 'darken' }}>
    <g style={{ 'mixBlendMode': 'darken' }} transform='translate(222.762 8.57391)'>
      <path d='M30.9288 2.68591C31.6527 0.606556 33.9209 -0.496799 36.0037 0.217286L46.0349 3.65657C48.0928 4.36212 49.2103 6.58336 48.5407 8.65317C36.286 46.534 28.4975 62.2597 17.7377 105.926C15.4752 115.334 17.4905 118.716 21.0947 121.877C21.5136 122.244 22.0183 122.507 22.5541 122.659C36.0669 126.511 44.3888 128.439 52.5168 136.213C52.6632 136.353 52.8216 136.485 52.986 136.604C59.7138 141.453 64.1572 147.301 61.7378 153.926C59.2887 160.632 40.4505 184.221 31.2377 210.926C22.3855 239.408 21.1784 253.567 22.7377 284.926C23.2599 296.626 22.5829 302.077 18.4595 304.541C18.0514 304.785 17.5346 304.656 17.2516 304.274C9.28406 293.518 -0.12669 277.31 2.73773 250.426C8.82774 164.822 10.0277 154.133 5.23772 140.926C-5.14044 112.31 1.99364 94.5287 8.69063 66.6226C8.72174 66.4929 8.75969 66.363 8.80351 66.2372L30.9288 2.68591Z' fill='#E5E5E5' />
    </g>
  </g>
  <defs>
    <radialGradient id='paint0_radial' cx='0.5' cy='0.5' r='0.5' gradientUnits='userSpaceOnUse' gradientTransform='matrix(692 404 -394.124 675.084 -193.938 -381.542)'>
      <stop offset='0.469613' stopColor='#37232F' />
      <stop offset='0.690608' stopColor='#683453' stopOpacity='0.53' />
    </radialGradient>
  </defs>
</svg>
